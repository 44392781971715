import React from 'react';
// import {motion} from "framer-motion";
// import { useRef, useEffect, useState } from 'react';

export default function ProjectItem({ projekt }) {
    // const [width, setWidth] = useState(0);
    // const carousel = useRef();
    // const pics = [projekt.image, projekt.image2, projekt.image3];

    // useEffect(() => {
    //     setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
    // },[]);

    return (
        <article className="project">
            {/* <motion.div ref={carousel} className='carousel' whileTap={{cursor: "grabbing"}}>
                <motion.div drag="x" dragConstraints={{right: 0, left: -width }} className='inner-carousel' whileTap={{cursor: "grabbing"}}>
                    {pics.map(pic => {
                        return (
                            <motion.div className='item' key={pic} whileTap={{cursor: "grabbing"}}>
                                <img src={pic} alt={projekt.title} whileTap={{cursor: "grabbing"}}/>
                            </motion.div>
                        );
                    })}
                </motion.div>
            </motion.div> */}
            <img src={projekt.image} alt={projekt.title} />
            <h2>{projekt.title}</h2>
            <div className="projects-grid">
                <div className="project-content">
                    <p>{projekt.summary}</p>
                    <p>{projekt.description}</p>
                </div>
                <div className="project-tools">
                    <h3>{projekt.tooltitle}</h3>
                    <ul>
                        <li>{projekt.tool1}</li>
                        <li>{projekt.tool2}</li>
                        <li>{projekt.tool3}</li>
                        <li>{projekt.tool4}</li>
                        <li>{projekt.tool5}</li>
                        <li>{projekt.tool6}</li>
                        <li>{projekt.tool7}</li>
                        <li>{projekt.tool8}</li>
                    </ul>
                </div>
            </div>
            {<div>
                {projekt.link ? (
                <a href={projekt.link} target="blank">
                    Link
                    </a>
                ) : (
                <p>No link</p>
                )}
            </div> }
        </article>
    );
}
