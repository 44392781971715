
export default function MainItem({ main }) {
    return (
        <article className="main-article">
            <p>{main.content2}</p>
            <p>{main.content3}</p>
            <p>{main.content4}</p>
        </article>
    );
}
