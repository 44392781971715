import { NavLink } from "react-router-dom";
import LanguageSelector from './LanguageSelector';
import React from 'react';
import { LangContext } from '../Contexts/LangContext';
export default function Nav() {
    const lang = React.useContext(LangContext);
    return (
        <nav>
            {lang[0] === "dk" ? <NavLink to="/" end>Hjem</NavLink> : <NavLink to="/" end>Home</NavLink>}
            {lang[0] === "dk" ? <NavLink to="/projects">Projekter</NavLink> : <NavLink to="/projects">Projects</NavLink>}
            {lang[0] === "dk" ? <NavLink to="/about">Om mig</NavLink> : <NavLink to="/about">About</NavLink>}
            <LanguageSelector />
            
            
        </nav>
    );
}
