import React, { useContext } from "react";
import { LangContext } from "../Contexts/LangContext";

const LanguageSelector = (props) => {
  const [lang, setLang] = useContext(LangContext);

  let handleChange = (event) => {
    setLang(event.target.value);
    localStorage.setItem("lang", event.target.value);
    document.documentElement.setAttribute("lang", event.target.value);
  };

  return (
    <>
      { <form className="lang-form" onChange={handleChange} value={lang}>
      <label for="english" className="english">EN</label>
      <input type="radio" id="english" value="en" name="language"></input>
      <label for="dansk" className="dansk">DA</label>
      <input type="radio" id="dansk" value="dk" name="language"></input>
      </form>
      }

    </>
  );
};
export default LanguageSelector;